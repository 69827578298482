/*
 * Name        : DFA ACF Google Maps
 * Version     : 0.6.1
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Builds Google Map from ACF fields
*/

import { $, $window } from '../_var';
import { waitForFinalEvent } from './_final_event';
import { choose_random } from './_choose_random';
import '../lib/markerclusterer.js';
import '../google-map-styles.js';

const add_acf_google_map_marker = ( $marker, m, marker_options ) =>
{
	const marker_html = $marker.html();
	const latlng = new google.maps.LatLng( $marker.attr( 'data-lat' ), $marker.attr( 'data-lng' ) );
	const default_marker_options = {
		position : latlng,
		map      : m,
	};

	const marker_settings = $.extend( {}, default_marker_options, marker_options );

	const marker = new google.maps.Marker( marker_settings );
	m.markers.push( marker );

	if ( marker_html )
	{
		google.maps.event.addListener( marker, 'click', () =>
		{
			m.info_window.setContent( marker_html );
			m.info_window.open( m, marker );
		} );
	}
};

const center_acf_google_map = ( m ) =>
{
	const bounds = new google.maps.LatLngBounds();

	m.markers.forEach( marker =>
	{
		const latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
		bounds.extend( latlng );
	} );

	if ( 1 === m.markers.length )
	{
		m.setCenter( bounds.getCenter() );
	}
	else
	{
		m.fitBounds( bounds );

		$window.on( 'resize', ( event ) =>
		{
			waitForFinalEvent( m.fitBounds( bounds ), 100, 'DFA_fitBounds' );
		});
	}
};

const make_acf_google_map = ( $el ) =>
{
	// Get the markers for this map
	const $markers     = $el.find( '.dfa-acf-map-marker' );
	const map_settings = {
		mapTypeControl : $el.data( 'maptypecontrol' ) || false,
		mapTypeId      : $el.data( 'maptype' )        || google.maps.MapTypeId.ROADMAP,
		scrollwheel    : $el.data( 'scrollwheel' )    || false,
		zoom           : $el.data( 'zoom' )           || 12,
	};

	// Set defaults
	const defaults = {
		center            : new google.maps.LatLng( 0, 0 ),
		jQuery_element    : $el,
		mapTypeControl    : map_settings.mapTypeControl,
		mapTypeId         : map_settings.mapTypeId,
		scrollwheel       : map_settings.scrollwheel,
		styles            : MAP_STYLES,
		zoom              : map_settings.zoom,
	};
	// Overload settings
	const settings = defaults;

	// Create map
	const map       = new google.maps.Map( $el[ 0 ], settings );
	map.markers     = [];
	map.info_window = new google.maps.InfoWindow();

	// Add markers
	if ( 0 < $markers.length  )
	{
		$markers.each( function( index )
		{
			const $element = $( this );
			add_acf_google_map_marker( $element, map, $element.data( 'marker-options' ) );
		} );

		init_markerclusterer( { map : map, markers : map.markers } );
	}

	// Center map
	center_acf_google_map( map );

	// Return
	return map;
};

const init_markerclusterer = ( obj ) =>
{
	// Bail if not defined.
	if ( 'undefined' === typeof MarkerClusterer || 'undefined' === typeof obj.map || 'undefined' === typeof obj.markers )
	{
		return;
	}

	const markerclusterer_styles       = [];
	const markerclusterer_styles_sizes = ( 'undefined' === typeof obj.sizes ) ? [ 53, 56, 66, 78, 90 ] : obj.sizes;

	const css_hack = 'dfa-dont-remove:('; // Check out limitations of ClusterIcon.prototype.createCss() in markerclusterer.js to see why we're doing this.

	for ( let i = 0, size; i < markerclusterer_styles_sizes.length; i++ )
	{
		size = markerclusterer_styles_sizes[ i ];
		markerclusterer_styles.push(
		{
			height    : size,
			textColor : '#ffffff',
			url       : `${DFA.PATHS.stylesheet_directory_uri}/images/icons/markers/m${i + 1}@2x.png); background-size: cover;${css_hack}`,
			width     : size,
		} );
	}

	const markerclusterer = new MarkerClusterer( obj.map, obj.markers, {
		'styles' : markerclusterer_styles,
	} );
};

const setup_acf_google_maps = () =>
{
	const acf               = {};
	acf.google_maps         = {};
	acf.google_maps.new_map = null;
	acf.google_maps.$maps   = $( '.dfa--map-canvas' );

	// Only proceed if we have google object and some maps
	if ( undefined !== typeof google && 0 < acf.google_maps.$maps.length )
	{
		acf.google_maps.$maps.each( function( index )
		{
			acf.google_maps.$maps[ index ] = make_acf_google_map( $( this ) );
		} );
	}
};

export { setup_acf_google_maps };
