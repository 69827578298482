/*
 * Name        : DFA Submenu Toggles
 * Version     : 1.0.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Submenu Toggles
*/

import { $, $html_and_body } from './_var';

// Adds toggle button/switch to menu items with children
const add_submenu_toggles = ( obj ) =>
{
    let defaults = {
        $el             : $( '.menu-item-has-children > a' ), // link with sub-menu
        $toggle_switch   : '<span class="menu-item-has-children-toggle"></span>', // HTML toggle switch to be inserted
        $toggle_selector : '.menu-item-has-children-toggle',
    };

    let settings = $.extend( defaults, obj );

    if ( 0 < settings.$el.length )
    {
        settings.$el.after( settings.$toggle_switch ); // Insert toggle switch
        let $toggle = $( settings.$toggle_selector ); // Must happen after switch is inserted

        $toggle.on( 'click', function()
        {
            let $this_toggle      = $( this );
            let $this_sub_menu    = $this_toggle.siblings( '.sub-menu' );
            let $parent_container = $this_toggle.parent();

            if ( $parent_container.hasClass( 'is-active' ) )
            {
                $parent_container.removeClass( 'is-active' );
            }
            else
            {
                $parent_container.addClass( 'is-active' );
            }
        } );
    }
};

// const init_scroll_to_element = ( obj ) =>
// {
//     let defaults = {
//         $el : $( '[data-scroll-btn]' )
//     };
//     let settings = $.extend( defaults, obj );
//
// 	if ( 0 < settings.$el.length )
// 	{
//         settings.$el.on( 'click', function( event )
//         {
//             let $anchor = $( this ).data( 'scroll-btn' );
//             let $el_target = $( $anchor );
//
//             $html_and_body.animate(
//             {
//                 scrollTop : $el_target.offset().top
//             }, 800 );
//         } );
//     }
//
// };

const setup_toggles = () =>
{
    add_submenu_toggles();
    // init_scroll_to_element();
};

export { setup_toggles };
