/* jshint -W079 */
/*
 * Name        : DFA Module
 * Version     : 0.2.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Establish some constants for use inside other modules.
*/

const $              = jQuery;
const $document      = $( document );
const $window        = $( window );
const $html          = $( 'html' );
const $body          = $( 'body' );
const $html_and_body = $html.add( $body );

// Use this to help detect media query states
const $menu_button   = $( '.menu-button' );

export { $, $document, $window, $html, $body, $html_and_body, $menu_button };
