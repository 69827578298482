/*
* Name        : DFA Retina-ready Assets
* Version     : 0.3.0
* Author      : Deep Fried Advertising
* Author URL  : https://deepfriedads.com
* Description : Swaps in retina assets when detected.
*/

import { $ } from '../_var';

const is_retina = () => window.devicePixelRatio > 1;

const use_retina = ( $selector ) =>
{
	$selector.each( function( index )
	{
		let $el        = $( this );
		let retina_src = $el.data( 'retina-src' );
		if ( retina_src )
		{
			$el.attr( 'src', retina_src );
		}
	} );
};

const setup_retina_assets = () =>
{
	if ( true === is_retina() )
	{
		let $all_imgs = $( 'img' );
		use_retina( $all_imgs );
	}
};

export { setup_retina_assets };
