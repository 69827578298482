/*
 * Name        : DFA Choose Random Module
 * Version     : 0.3.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Choose a random item from array.
*/

const choose_random = ( dfa_choices ) =>
{
	// Only start work if dfa_choices is defined.
	if ( undefined !== dfa_choices )
	{
		let dfa_choices_length = 0;

		// If object instead of array, convert to array.
		if ( undefined === dfa_choices.length && 'object' === typeof dfa_choices )
		{
			dfa_choices = Object.keys( dfa_choices ).map( ( key ) => dfa_choices[ key ] );
		}

		// Cache dfa_choices.length for performance
		dfa_choices_length = dfa_choices.length;

		if ( dfa_choices_length > 1 )
		{
			dfa_choices = dfa_choices[ Math.floor( Math.random() * dfa_choices_length ) ];
		}
	}

	return dfa_choices;
};

export { choose_random };
