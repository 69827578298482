/*
 * Name        : DFA Fancybox
 * Version     : 1.0.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Fancybox
*/
import { $ } from './_var';
import './lib/jquery.fancybox/jquery.fancybox';

export default function ()
{
	const $dfa_fancybox = $('[data-fancybox]');
	$dfa_fancybox.fancybox({
		'transitionIn'	:	'elastic',
		'transitionOut'	:	'elastic',
		'speedIn'		:	600,
		'speedOut'		:	200,
		'overlayShow'	:	true
	});
};
