const WebFontConfig =
{
	google :
	{
		families :
		[
		]
	}
};

if ( WebFontConfig.google.families.length > 0 )
{
	(function( d )
	{
		let wf   = d.createElement( 'script' );
		let s    = d.getElementsByTagName( 'script' )[ 0 ];
		wf.src   = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
		wf.async = 'true';
		s.parentNode.insertBefore( wf, s );
	})( document );
}

export { WebFontConfig };
