/*
 * Name        : DFA Main Nav Module
 * Version     : 0.3.1
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Adds click event listeners to `.main-nav a`
*/

import { $, $html, $menu_button } from '../_var';

const $main_nav = $( '.main-nav' );

const setup_main_nav = () =>
{
	$main_nav.on( 'click', 'a', function( event )
	{
		// Store/cache the link as a local variable
		let this_nav_item_link = $( this );

		// Handle: a[href="#"]
		if ( '#' === this_nav_item_link.attr( 'href' ) )
		{
			event.preventDefault();
		}

		// Handle: a.menu-button
		if ( this_nav_item_link.is( $menu_button ) )
		{
			event.preventDefault();
			$html.toggleClass( 'main-nav-items-open' );
		}
	} );
};

export { setup_main_nav };
