/*
 * Name        : DFA Locations Map
 * Version     : 1.0.0
 * Author URL  : http://deepfriedads.com
 * Description : Setup Locations Map
*/

import { $ } from './_var';

const $locations_map = $( '.locations-map' );

const add_locations_map_marker_options = () =>
{
	$locations_map.find( '.dfa-acf-map-marker' ).each( function( index )
	{
		let $marker = $( this );
		let locations_map_marker_options = {};

		if ( undefined !== $marker.attr( 'data-marker-icon' ) )
		{
			locations_map_marker_options.icon = {
				scaledSize : new google.maps.Size( 35, 50 ),
				url        : $marker.attr( 'data-marker-icon' ),
			};
		}

		$marker.data( 'marker-options', locations_map_marker_options );
	} );
};

const setup_map_options = () =>
{
	add_locations_map_marker_options();
};

export { setup_map_options };
