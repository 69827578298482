/*
 * Name        : DFA Sliders
 * Version     : 1.0.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup BX Slider
*/

import { $, $window } from './_var';
import './lib/jquery.bxslider/jquery.bxslider';

const init_main_slider = () =>
{
	let $main_slider = $( '.main-slider' ).find( '.bxslider' );

	$main_slider.bxSlider(
	{
		mode : 'fade',
		auto : true,
		autoHover : true,
		// pager : true,
		touchEnabled : false,
		preventDefaultSwipeX : true,
		controls : false,
		pager : ( 1 < $main_slider.children().length ), /* show controls if more than 1 */
	});
};

// const init_gallery_slider = () =>
// {
// 	let $gallery_slider = $( '.gallery-slider' ).find( '.bxslider' );
//
// 	$gallery_slider.bxSlider(
// 	{
// 		// minSlides: 3,
// 		// maxSlides: 3,
// 		// moveSlides: 1,
// 		pager: false,
// 		// slideWidth: 1000,
// 	});
// };

const setup_sliders = () =>
{
	init_main_slider();
	// init_gallery_slider();
};

export { setup_sliders };
