/*
 * Name        : DFA Fitvids
 * Version     : 1.0.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfriedads.com
 * Description : Setup Fitvids
*/

import { $body } from './_var';

export default function ()
{
    $body.fitVids();
}
