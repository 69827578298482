import { WebFontConfig } from './_web_fonts';
window.WebFontConfig = WebFontConfig; // Expose as global variable for webfont loader JS

import { setup_main_nav } from './deep-fried-modules/_main_nav';
import { setup_retina_assets } from './deep-fried-modules/_retina';
import { setup_acf_google_maps } from './deep-fried-modules/_acf_google_maps';
import { setup_toggles } from './_toggles';
import { setup_sliders } from './_sliders';
import setup_fitvids from './_fitvids';
import setup_fancybox from './_fancybox';
import { setup_map_options } from './_locations';

jQuery( document ).ready( ( $ ) =>
{
	setup_main_nav();
	setup_retina_assets();
	setup_map_options();
	setup_acf_google_maps();
	setup_toggles();
	setup_sliders();
	setup_fitvids();
	setup_fancybox();
} );
